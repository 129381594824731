import React, { useCallback } from 'react';
import { graphql, PageProps } from 'gatsby';
import { ArrowRightIcon, ArrowLeftIcon } from '@aviationexam/icons';
import { ActionIcon, Col, Container, Grid, Group, Title } from '@aviationexam/core';
import { SEO } from 'components';
import { PostNode } from 'types/post';
import { BLOG_PAGE } from 'config/routes';
import { usePublicTranslation, useTranslatedRoute } from 'features/localization';
import { BlogPostThumbnail, BlogCategoryNav } from './components';
import useStyles from './Blog.styles';

interface DataType {
  allBlogPost: {
    edges: Array<{
      node: PostNode;
    }>;
  };
}

interface Context {
  locale: 'en' | 'de';
  category: string;
  categoryLabel: string;
  categories: Array<{ categoryKey: string; categoryLabel: string }>;
  skip: number;
  limit: number;
  total: number;
}

function BlogPage({
  data: { allBlogPost },
  pageContext: { locale, skip, limit, total, categoryLabel, category, categories },
  navigate,
}: PageProps<DataType, Context>) {
  const { t } = usePublicTranslation();
  const route = useTranslatedRoute(locale);
  const { classes } = useStyles();

  const path = useCallback(() => {
    const page = skip / limit;
    const nextSuffix = `/${page + 1}`;
    const prevSuffix = page === 1 ? '' : `/${page - 1}`;
    return {
      next: `${route(BLOG_PAGE)}/${category}${nextSuffix}`,
      prev: `${route(BLOG_PAGE)}/${category}${prevSuffix}`,
    };
  }, [skip, limit, route, category]);

  const posts = allBlogPost.edges.map(edge => edge.node);

  return (
    <>
      <SEO title={t('blogPage.title')} description={t('site.description')} lang={locale} />
      <BlogCategoryNav categories={categories} category={category} locale={locale} />
      <Container size="xl" className={classes.container}>
        <Grid>
          <Col span={12}>
            <Title order={1} my="xl" className={classes.title}>
              {t('blogPage.categoryHeading', { category: categoryLabel })}
            </Title>
          </Col>
          {posts.map((node, i) => (
            <Col
              key={node.id}
              span={12}
              xs={i === 0 && skip === 0 ? 12 : 6}
              md={i === 0 && skip === 0 ? 12 : 4}
            >
              <BlogPostThumbnail
                title={node.frontmatter.postTitle[locale]}
                author={node.frontmatter.author}
                date={node.frontmatter.date}
                locale={node.frontmatter.locale}
                route={node.frontmatter.urls[locale] ?? node.frontmatter.urls.en}
                description={node.frontmatter.postDescription[locale]}
                featuredImage={node.frontmatter.featuredImage}
                featured={i === 0 && skip === 0}
              />
            </Col>
          ))}
          <Col span={12}>
            <Group>
              <ActionIcon
                variant="filled"
                disabled={skip === 0}
                title={t('blogPage.prevPage')}
                onClick={() => navigate(path().prev)}
              >
                <ArrowLeftIcon />
              </ActionIcon>
              <ActionIcon
                variant="filled"
                disabled={skip + limit >= total}
                title={t('blogPage.prevPage')}
                onClick={() => navigate(path().next)}
              >
                <ArrowRightIcon />
              </ActionIcon>
            </Group>
          </Col>
        </Grid>
      </Container>
    </>
  );
}

export default BlogPage;

export const pageQuery = graphql`
  query BlogPostsByCategory($ids: [String!]) {
    allBlogPost(filter: { id: { in: $ids } }, sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            postTitle {
              en
              de
            }
            author
            date
            postDescription {
              en
              de
            }
            urls {
              en
              de
            }
            locale
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  quality: 100
                  placeholder: BLURRED
                  formats: [WEBP, AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`;
